html,
body,
#root {
    height: 100%;
}

body {
    margin: 0;
    padding: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: 'Noto Sans Mono', Menlo, Monaco, 'SFMono-Regular', Consolas,
        'Liberation Mono', Courier, monospace;
    padding-left: 4px;
    padding-right: 4px;
    padding-top: 2px;
    padding-bottom: 2px;
    color: #c7254e;
    border-radius: 4px;
}

#main-content-layout {
    overflow-x: hidden;
}

.full-screen {
    width: 100%;
    height: 100%;
}

hr {
    opacity: 0.4;
}

.unselectable-span {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.hide-on-demand {
    display: none !important;
}

.code-input {
    font-family: 'Noto Sans Mono', Menlo, Monaco, 'SFMono-Regular', Consolas,
        'Liberation Mono', Courier, monospace !important;
}

.netdata-field {
    margin-bottom: 20px;
}

.logs-output {
    font-family: 'Noto Sans Mono', Menlo, Monaco, 'SFMono-Regular', Consolas,
        'Liberation Mono', Courier, monospace !important;
    overflow: scroll;
    min-height: 250px !important;
    border-width: 2px;
    border-radius: 5px;
    padding: 10px;
    font-size: 90%;
    width: 100%;
}

.disabled {
    opacity: 0.4;
    cursor: hand;
    pointer-events: none;
}

.one-click-app-card {
    width: 18em;
    margin: 1em;
}

.one-click-app-card img {
    -webkit-transition: 0.3s ease-in-out;
    -o-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;
    opacity: 1;
    padding: 24px;
    padding-left: 12px;
    height: 9em;
    width: 100%;
    margin-top: 0;
    -o-object-fit: contain;
    object-fit: contain;
}

.one-click-app-card:hover img {
    opacity: 0.8;
}

.pre-line-content {
    white-space: pre-wrap;
}

.slow-fadein-slow {
    animation: fadeIn 0.5s ease-in;
}

.slow-fadein-fast {
    animation: fadeIn 0.1s ease-in;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
